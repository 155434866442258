<template>
    <div>

    </div>
</template>

<script>
export default {
    name: "DetailRegalu",
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>